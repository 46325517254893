













import { Component, Mixins } from 'vue-property-decorator';
import { FormAction } from '@/app/core/types';
import RedirectableMixin from '@/app/core/mixins/redirectable.mixin';
import HandlesErrorMixin from '@/app/core/mixins/handles-error.mixin';
import ApartmentDto from '../dto/apartment.dto';
import ApartmentForm from '../components/apartment-form.vue';
import apartmentsService from '../services/apartments.service';

@Component({
  metaInfo(this: UpdateApartment) {
    return { title: this.$t('pageTitle.updateApartment').toString() };
  },
  components: {
    ApartmentForm,
  },
})
export default class UpdateApartment extends Mixins(HandlesErrorMixin, RedirectableMixin) {
  private loading = false;
  private action = FormAction.Update;
  private apartmentId: string | null = null;
  private apartment: ApartmentDto | null = null;

  async updateApartment(dto: ApartmentDto) {
    if (!this.apartmentId) return;

    this.loading = true;

    try {
      await apartmentsService.updateApartment(this.apartmentId, dto);
      this.$router.push(this.redirect || '/apartments');
      this.$notify.success(this.$t('success.apartmentUpdated').toString());
    } catch (error) {
      if (this.isNotFoundError(error)) {
        this.$router.push({ name: '404' });
      } else {
        this.handleError(error);
      }
    } finally {
      this.loading = false;
    }
  }

  async getApartment() {
    if (!this.apartmentId) return;

    this.loading = true;

    try {
      const response = await apartmentsService.getApartment(this.apartmentId);
      this.apartment = response.data;
    } catch (error) {
      if (this.isNotFoundError(error)) {
        this.$router.push({ name: '404' });
      } else {
        this.handleError(error);
      }
    } finally {
      this.loading = false;
    }
  }

  created() {
    this.apartmentId = this.$route.params.id;

    this.getApartment();
  }
}
